import React from "react";

import { css } from "@emotion/css";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";

const Hero = ({techText, techImage}) => {
  const styles = style(techImage);

  return (
    <div>
      <div className={styles.bg}></div>
      <div className={styles.container}>
        <div className="wrapper">
          <h1>
            Tech
            {/* <br /> */}
            nology
            <img
              src={techImage[0] && techImage.filter(item => item.location =="hero sub logo")[0].image.asset.url}
              alt="Logo"
              className="logo"
            />
          </h1>
          <p className="text" style={{ whiteSpace: 'pre-line'}}>
            {techText[0] && techText.filter(item => item.type =="main description")[0].title}
          </p>
        </div>
      </div>
    </div>
  );
}

const style =(techImage) => ({
  bg: css({
    backgroundImage: `url(${techImage[0] && techImage.filter(item => item.location == "hero background image")[0].image.asset.url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    transition: "all .4s ease",
    width: "100%",
    height: '100%',
    opacity: 0.95,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,

    [tablet]: {
      backgroundImage: `url(${techImage[0] && techImage.filter(item => item.location == "hero background mobile image")[0].image.asset.url})`,
      backgroundPosition: "center -150px",
      backgroundSize: "cover",
    }
  }),
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    paddingBottom: 150,
    zIndex: 1,
    position: 'relative',

    h1: {
      color: "#fff",
      fontFamily: 'Halvar Breitschrift',
      fontSize: 'clamp(2rem, 10vw, 10rem)', // 최소값, 중간값, 최대값 설정
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '95%',
      letterSpacing: '-6.991px',
      marginTop: 40,
      display: 'flex',
      alignItems: 'flex-start', // 이미지와 텍스트의 윗라인을 일치시킴
    },

    ".text": {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      // color: "#1B243A",
      letterSpacing: "-0.01em",
      textAlign: "left",
      marginBottom: 20,
      marginTop: 10,
      maxWidth: 479,
      width: '100%',
      marginTop: 40,
    },
    ".logo": {
      // marginBottom: 60,
      marginLeft: 'clamp(0.5rem, 1.5vw, 2rem)', // 최소값, 중간값, 최대값 설정
      width: 'clamp(1rem, 4vw, 4rem)', // 최소값, 중간값, 최대값 설정
      height: 'clamp(1rem, 4vw, 4rem)', // 최소값, 중간값, 최대값 설정
    },
    [laptop]: {
      h1: {
        fontSize: 'clamp(2rem, 5vw, 6.25rem)', // 최소값, 중간값, 최대값 설정
        fontWeight: 400,
        lineHeight: '105%',
        letterSpacing: '-4px',
        marginBottom: 54,
        marginTop: 30,
      },

      ".text": {
        color: "#1B243A",
        fontSize: 14,
        lineHeight: '24px'

    },
    ".logo": {
      // marginBottom: 60,
      marginLeft: 'clamp(0.5rem, 1.5vw, 2rem)', // 최소값, 중간값, 최대값 설정
      width: 'clamp(1rem, 3vw, 4rem)', // 최소값, 중간값, 최대값 설정
      height: 'clamp(1rem, 3vw, 4rem)', // 최소값, 중간값, 최대값 설정
    }
  },

    [tablet]: {
      paddingLeft: 30,
      paddingRight: 30,
      h1: {
        fontSize: 'clamp(2rem, 5vw, 4.6875rem)', // 최소값, 중간값, 최대값 설정
        letterSpacing: '-3px',
        marginBottom: 30,
      },

    },

    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,

      h1: {
        fontSize: 'clamp(2rem, 5vw, 3.5rem)', // 최소값, 중간값, 최대값 설정
        letterSpacing: '-2px',
        marginBottom: 30,
      },
    },
  })
});

export default Hero;
