import React from "react";

const SizeGuide = () => {
  return (
    <div style={styles.sizeGuideContainer}>
      <h3 style={styles.subtitle}>Size Guide</h3>
      <h4 style={styles.findSizeText}>Find the Right Size</h4>

      {/* <div style={styles.radioButtons}>
        <label style={styles.label}>
          <input type="radio" name="unit" defaultChecked /> cm
        </label>
        <label style={styles.label}>
          <input type="radio" name="unit" /> inches
        </label>
      </div> */}

      <table style={styles.sizeTable}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>HyperRing size</th>
            <th style={styles.tableHeader}>US</th>
            <th style={styles.tableHeader}>EU</th>
            <th style={styles.tableHeader}>UK</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
              <td style={styles.tableData}>{row.hyperRing}</td>
              <td style={styles.tableData}>{row.us}</td>
              <td style={styles.tableData}>{row.eu}</td>
              <td style={styles.tableData}>{row.uk}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// 스타일 정의
const styles = {
  sizeGuideContainer: {
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "20px",
    textAlign: "left", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "10px",
  },
  findSizeText: {
    fontSize: "18px",
    textAlign: "left", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "20px",
  },
  radioButtons: {
    display: "flex",
    justifyContent: "flex-start", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "20px",
  },
  label: {
    marginRight: "20px",
    fontSize: "16px",
  },
  sizeTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    padding: "10px",
    border: "1px solid #ddd",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableData: {
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#f9f9f9",
  },
  oddRow: {
    backgroundColor: "white",
  },
};

// 테이블 데이터
const tableData = [
  { hyperRing: 4, eu: 47, uk: "H½", us: "4" },
{ hyperRing: 5, eu: 49, uk: "J½", us: "5" },
{ hyperRing: 6, eu: 52, uk: "L½", us: "6" },
{ hyperRing: 7, eu: 54, uk: "N½", us: "7" },
{ hyperRing: 8, eu: 57, uk: "P½", us: "8" },
{ hyperRing: 9, eu: 59, uk: "R½", us: "9" },
{ hyperRing: 10, eu: 62, uk: "T½", us: "10" },
{ hyperRing: 11, eu: 65, uk: "V½", us: "11" },
{ hyperRing: 12, eu: 67, uk: "Y", us: "12" },
{ hyperRing: 13, eu: 70, uk: "Z½", us: "13" },
{ hyperRing: 14, eu: 73, uk: "Z+1", us: "14" },

];

export default SizeGuide;
