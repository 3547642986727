import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import SubLogo from "../../../img/svg/home/sub-logo.svg";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const Hero = ({ teamText, teamImage }) => {
  const [teamVideo, setTeamVideo] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "teamVideo"] {
            location,
            video{
              asset -> {
                _id,
                url
              }
            },
        }`
      )
      .then((data) => {
        setTeamVideo(data);
        setIsDataLoaded(true);
      })
      .catch(console.error);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className={style.container}>
        {isDataLoaded && (
          <>
            <video className={`desktop`} autoPlay loop muted>
              <source
                src={
                  teamVideo.filter(
                    (item) => item.location == "hero background"
                  )[0]?.video?.asset?.url
                }
                type="video/mp4"
              />
            </video>
            <video className={`mobile`} autoPlay loop muted playsInline webkit-playsinline="true" x5-playsinline="true" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}>
              <source
                src={
                  teamVideo.filter(
                    (item) => item.location == "hero background mobile"
                  )[0]?.video?.asset?.url
                }
                type="video/mp4"
              />
            </video>
          </>
        )}
        <div className="wrapper">
          <h1 style={{ whiteSpace: "pre-line" }}>
            {teamText[0] &&
              teamText.filter((item) => item.type == "hero")[0].title}
            <img
              src={
                teamImage[0] &&
                teamImage.filter(
                  (item) => item.location == "hero sub logo"
                )[0].image.asset.url
              }
              alt="Logo"
              className="logo"
            />
          </h1>

          <p className="text" style={{ whiteSpace: "pre-line" }}>
            {teamText[0] &&
              teamText.filter((item) => item.type == "hero")[0].content}
          </p>
        </div>
      </div>
      <div className={style.divider}></div>
    </div>
  );
};

const style = {
  divider: css({
    position: "absolute",
    textAlign: "center",
    height: 150,
    width: "100%",
    bottom: 0,
    background: "linear-gradient(0deg, #f4f4f4, hsl(0deg 0% 100% / 0%))",
  }),
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    paddingBottom: 300,

    ".desktop": {
      display: "block",
    },
    ".mobile": {
      display: "none",
    },
    video: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      left: 0,
      top: 0,
      zIndex: "-1",
    },
    h1: {
      color: "#fff",
      fontFamily: "Halvar Breitschrift",
      fontSize: 'clamp(2rem, 5vw, 6.25rem)', // 최소값, 중간값, 최대값 설정
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5%",
      display: 'flex',
      alignItems: 'flex-start', // 이미지와 텍스트의 윗라인을 일치시킴
      // textTransform: "lowercase",
      
      marginTop: 40,
    },
    ".logo": {
      // marginBottom: 60,
      marginLeft: 'clamp(0.5rem, 1.5vw, 2rem)', // 최소값, 중간값, 최대값 설정
      width: 'clamp(1rem, 3vw, 4rem)', // 최소값, 중간값, 최대값 설정
      height: 'clamp(1rem, 3vw, 4rem)', // 최소값, 중간값, 최대값 설정
    },
    ".text": {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",      

      // color: "#1B243A",
      // lineHeight: "24px",
      textAlign: "left",
      marginBottom: 20,
      marginTop: 10,
      maxWidth: 458,
      width: "100%",
      // marginLeft: 126,
      marginTop:40,
    },
    [desktop]: {
      ".text": {
        // marginLeft: 70,
      },
    },
    [laptop]: {
    },
    [tablet]: {
      padding: 30,
      paddingBottom: 400,

      ".desktop": {
        display: "none",
      },
      ".mobile": {
        display: "block",
      },

      ".text": {
        marginLeft: 0,
        fontSize: 14,
      },
    },
    [mobile]: {
      padding: 20,
      paddingBottom: 400,

    },
  }),
};

export default Hero;
