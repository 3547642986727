import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import SubLogo from "../../../img/svg/home/sub-logo.svg";
import BGVideo from "../../../video/mp4/contact-video.mp4";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const Hero = () => {
  const [contactImage, setContactImage] = useState([]);
  const [contactVideo, setContactVideo] = useState([]);

  useEffect(() => {
    client.fetch(
    `*[_type == "contactImage"] {
        location,
        image{
          asset -> {
            _id,
            url
          }
        },
    }`
    ).then((data) => {
      setContactImage(data);
    }).catch(console.error);
},[]);

useEffect(() => {
    client.fetch(
    `*[_type == "contactVideo"] {
        location,
        video {
          asset -> {
            _id,
            url
          }
        }
    }`
    ).then((data) => {
      setContactVideo(data);
      console.log('video ',data);
    }).catch(console.error);
},[]);

  return (
    <div style={{ position: "relative" }}>
      <div className={style.container}>
        {
          contactVideo[0] && (<video className={style.bg} autoPlay loop muted playsInline webkit-playsinline="true" x5-playsinline="true" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}>
            <source src={contactVideo.filter(item => item.location == "hero background")[0].video.asset.url} type="video/mp4" />
          </video>)
        }
        <div className="wrapper">
          <h1>
            Partner With Us
            <img
                src={contactImage[0] && contactImage.filter(item => item.location == "hero sub logo")[0].image.asset.url}
                alt="Logo"
                className="logo"
              />
          </h1>
        </div>
      </div>
      <div className={style.divider}></div>
    </div>
  );
}

const style = {
  divider: css({
    position: "absolute",
    textAlign: "center",
    height: 150,
    width: "100%",
    bottom: 0,
    background: "linear-gradient(0deg, #f4f4f4, hsl(0deg 0% 100% / 0%))",
  }),
  container: css({
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,

    video: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      left: 0,
      top: 0,
      zIndex: "-1",
    },
    h1: {
      color: "#fff",
      fontFamily: "Halvar Breitschrift",
      fontSize: 'clamp(6rem, 6vw, 10rem)', // 최소값, 중간값, 최대값 설정
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      // textTransform: "lowercase",
      marginTop: 40,
      display: 'flex',
      alignItems: 'flex-start', // 이미지와 텍스트의 윗라인을 일치시킴
    },
    
    ".logo": {
      // marginBottom: 60,
      marginLeft: 'clamp(0.5rem, 1.5vw, 2rem)', // 최소값, 중간값, 최대값 설정
      width: 'clamp(3rem, 3vw, 3.5rem)', // 최소값, 중간값, 최대값 설정
      height: 'clamp(3rem, 3vw, 3.5rem)', // 최소값, 중간값, 최대값 설정
    },
    [laptop]: {
      h1: {
        fontSize: 'clamp(4em, 9vw, 10rem)', // 최소값, 중간값, 최대값 설정
      },
      ".logo": {
        // marginBottom: 60,
        marginLeft: 'clamp(0.5rem, 1.5vw, 2rem)', // 최소값, 중간값, 최대값 설정
        width: 'clamp(2rem, 4vw, 3rem)', // 최소값, 중간값, 최대값 설정
        height: 'clamp(2rem, 4vw, 3rem)', // 최소값, 중간값, 최대값 설정
      }
    },
    [tablet]: {

    },
    [mobile]: {

    }
  }),
};

export default Hero;
