import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { css } from "@emotion/css";

import DropdownIcon from "../../../img/svg/dropdown.svg";
import { Link } from "react-router-dom";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { firestore } from "../../../firebase";

const Data = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: "",
    type: "",
  });

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    // Hide alert after 5 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, message: "", type: "" });
    }, 5000);
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { title, name, email, phone, company, sector, message } = data;
    try {
      // Disable form while processing submission
      // setDisabled(true);

      const templateParams = {
        title: title,
        name: name,
        email: email,
        phone: phone,
        company: company,
        sector: sector,
        message: message,
      };

      console.log('contact',templateParams);

      const contact = firestore.collection("contact");
      contact.doc().set(templateParams);

      // Display success alert
      toggleAlert("Form submission was successful!", "success");
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert("Uh oh. Something went wrong.", "danger");
    } finally {
      // Re-enable form submission
      // setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <div className={style.container}>
      <h2>Contact</h2>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="row">
          <div className="select-wrapper">
            <select
              {...register("title", {
                required: { value: true, message: "Please select title" },
              })}
              placeholder="Select a Title"
            >
              <option value="" disabled selected>
                Please Select Title
              </option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
            </select>
            {errors.title && (
              <span className="error">{errors.title.message}</span>
            )}
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              {...register("name", {
                required: { value: true, message: "Please enter your name" },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
            ></input>
            {/* <label for="name">Full Name</label> */}
            {errors.name && (
              <span className="error">{errors.name.message}</span>
            )}
          </div>

          <div className="input-wrapper">
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              placeholder="Email Address"
            ></input>
            {errors.email && (
              <span className="error">Please enter a valid email address</span>
            )}
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              name="phone"
              {...register("phone", {
                required: { value: true, message: "Please enter your name" },
              })}
              placeholder="Phone Number"
            ></input>
            {errors.phone && (
              <span className="error">Please enter a Phone Number</span>
            )}
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              name="company"
              {...register("company", {
                required: { value: true, message: "Please enter your name" },
              })}
              placeholder="Company Name"
            ></input>
            {errors.company && (
              <span className="error">{errors.company.message}</span>
            )}
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              name="sector"
              {...register("sector", {
                required: { value: true, message: "Please enter your sector" },
              })}
              placeholder="Sector"
            ></input>
            {errors.sector && (
              <span className="error">{errors.sector.message}</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="input-wrapper full">
            <input
              type="text"
              name="message"
              {...register("message", {
                required: { value: true, message: "Please enter a message" },
              })}
              placeholder="Extra information e.g. Tell us a little more about your company and business model"
            ></input>
            {errors.message && (
              <span className="error">{errors.message.message}</span>
            )}
          </div>
        </div>
        <button className="submit-btn" type="submit">
          Submit
        </button>
      </form>
      {alertInfo.display && (
        <div
          className={`alert alert-${alertInfo.type} alert-dismissible`}
          role="alert"
        >
          {alertInfo.message}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() =>
              setAlertInfo({ display: false, message: "", type: "" })
            } // Clear the alert when close button is clicked
          ></button>
        </div>
      )}
      <Link to="/privacy" className="policy">
        Privacy Policy
      </Link>
    </div>
  );
};

const style = {
  container: css({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 200,
    paddingtop: 50,
    backgroundColor: "#f4f4f4",

    h2: {
      color: "#000",
      fontFamily: "Halvar Breitschrift",
      fontSize: 140,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      // textTransform: "lowercase",
      // background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      marginBottom: 100,
    },

    form: {
      maxWidth: 1100,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    },

    ".row": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      gap: 12,
      marginBottom: 12,
      width: "100%",

      ".select-wrapper": {
        position: "relative",
        "&:after": {
          content: '""',
          width: 24,
          height: 12,
          background: `url("${DropdownIcon}") 0 0 no-repeat`,
          top: 28,
          right: 5,
          position: "absolute",
          display: "block",
          zIndex: 0,
        },
      },
      ".input-wrapper, .select-wrapper": {
        flex: "49%",
        width: "100%",
        display: "flex",
        position: "relative",
      },

      //   ".input-wrapper input:focus + label, .input-wrapper input:not(:placeholder-shown) + label": {
      //   top: '-6px',
      //   fontSize: 12,
      //   color: '#007bff',
      //   backgroundColor: '#ffffff',
      //   padding: '0 4px',
      //   borderRadius: 4,
      //   left: 8,
      // },

      ".error": {
        position: 'absolute',
        color: 'red',
        bottom: 5,
        left: 25,
        fontSize: 12,
      },

      "input, select": {
        color: "#6D7DA1",
        fontFamily: "Suisse Intl",
        fontSize: 17.62,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "105%",
        borderRadius: 12,
        border: "1px solid rgba(109, 125, 161, 0.80)",
        padding: "20px 24px",
        width: "100%",
        backgroundColor: "#f4f4f4",

        "&:focus, &:active": {
          outline: "none",
          border: "1px solid #79A6FF",
          color: "#1B243A",
          backgroundColor: "#f4f4f4",
        },
      },
      select: {
        appearance: "none",
        position: "relative",

        option: {
          color: "var(--Colors-font, #6D7DA1)",
          fontFamily: "Suisse Intl",
          fontSize: 17.62,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "105%",
        },
      },
    },

    ".full": {
      flex: "100%",

      input: {
        maxWidth: "100%",
        width: "100%",
      },
    },

    button: {
      borderRadius: 12,
      border: "1px solid rgba(100, 115, 148, 0.10)",
      background: "rgba(255, 255, 255, 0.15)",
      backdropFilter: "blur(4px)",
      color: "#1B243A",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: 1.92,
      marginTop: 90,
      marginBottom: 30,
      maxWidth: 400,
      width: "100%",
      textAlign: "center",
      padding: 10,
      cursor: "pointer",

      "&:hover": {
        background: "rgb(229 229 229 / 31%)",
      },
    },

    ".policy": {
      color: "#6D7DA1",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      letterSpacing: 0.64,
    },

    [laptop]: {
      h2: {
        fontSize: 100,
      },
    },
    [tablet]: {

      h2: {
        fontSize: 75,
        marginBottom: 80,
      },
    },
    [mobile]: {
      h2: {
        fontSize: 46,
        marginBottom: 60,
        letterSpacing: '-1.84px',
      },
    }
  }),
};

export default Data;
