import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import EmailIcon from "../../../img/svg/icon/email.svg"; // TGIcon 대신 EmailIcon을 가져옴
import LIIcon from "../../../img/svg/icon/linkedin.svg";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";


const Members = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    client.fetch(
    `*[_type == "teamMember"] | order(id asc) {
        id,
        image{
          asset -> {
            _id,
            url
          }
        },
        name,
        title,
        email,
        linkedin
    }`
    ).then((data) => {
      setMembers(data);
      console.log('member',data);
    }).catch(console.error);
},[]);

  if(members.length== 0 ){
    return null;
  }

  return (
    <div className={style.container}>
      <h2>Team</h2>
      <div className="items">
        {Object.values(members).map((v) => (
          <div className="item">
            <span className="image-wrapper">
              <img className="avatar" src={v.image.asset.url} alt={v.name} style={{ width: '100%', height: '100%', objectFit: 'contain', aspectRatio: '1/1' }} />
              <span className="active">
                <a href={`mailto:${v.email}`} target="_blank" rel="noreferrer">
                  <img src={EmailIcon} alt="" />
                </a>
                <a href={`${v.linkedin}`} target="_blank" rel="noreferrer">
                  <img src={LIIcon} alt="" />
                </a>
              </span>
            </span>
            <h5>{v.name}</h5>
            <p>{v.title}</p>
          </div>
        ))}
      </div>
      <div className={style.divider}></div>
    </div>
  );
}

const style = {
  divider: css({
    position: "absolute",
    textAlign: "center",
    height: 150,
    width: "100%",
    bottom: "-150px",
    background: "linear-gradient(0deg, hsl(0deg 0% 100% / 0%), #f4f4f4)",
    zIndex: "-1",
  }),
  container: css({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 100,
    paddingtop: 50,
    backgroundColor: "#f4f4f4",

    h2: {
      color: "#000",
      fontFamily: "Halvar Breitschrift",
      fontSize: 140,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      // textTransform: "lowercase",
      // background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      marginBottom: 150,
    },

    ".items": {
      display: "flex",
      flexWrap: "wrap",
      alignContent: "flex-start",
      justifyContent: "flex-start", // 변경: justifyContent을 flex-start로 설정
      alignItems: "flex-start",
      width: "100%",
      maxWidth: 1080,

      ".item": {
        flex: "33.33%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 40,
        display: "block",
        width: "fit-content",
        width: '33.33%',
        maxWidth: 338,

        ".image-wrapper": {
          borderRadius: 12.993,
          marginBottom: 24,
          position: "relative",
          overflow: "hidden",
          display: "block",
          width: "fit-content",

          img: {
            display: "block",
          },

          ".active": {
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            background: "black",
            transition: "0.4s ease-out",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            alignContent: "flex-end",
            flexWrap: "nowrap",

            img: {
              margin: 25,
            },
          },

          "&:hover": {
            ".active": {
              opacity: 0.7,
            },
          },
        },

        p: {
          color: "rgba(27, 36, 58, 0.80)",
          fontFamily: "Suisse Intl",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",

          // color: "#1B243A",
          // lineHeight: "normal",
          letterSpacing: 0.64,
          textAlign: "left",
          display: "block",
          width: "fit-content",
          marginBottom: 30,
        },

        h5: {
          color: "#A8B2C9",
          fontFamily: "Halvar Breitschrift",
          fontSize: 22,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          textTransform: "uppercase",
          textAlign: "left",
          display: "block",
          width: "fit-content",
          marginBottom: 8,
        },
      },
    },
    [laptop]: {
      h2: {
        fontSize: 120,
        marginBottom: 100,
      },
      ".items": {
        ".item": {
          flex: "50%",
          marginBottom: 40,
          maxWidth: 400,

          '.image-wrapper':{
            '.avatar': {
              width: '100%',
              height: 'auto',
            }
          },
          p: {
            fontSize: 18,
          },

          h5: {
            fontSize: 24,
          },
        },
      },
    },
    [tablet]: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 80,
      h2: {
        fontSize: 100,
        marginBottom: 80,
      },
      ".items": {
        gap: 30,
        ".item": {
          flex: "45%",
          marginBottom: 40,
          maxWidth: 300,

          p: {
            fontSize: 16,
          },

          h5: {
            fontSize: 22,
          },
        },
      },
    },
    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 60,

      h2: {
        fontSize: 75,
      },
      ".items": {
        justifyContent: 'center',
        ".item": {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          flex: "100%",
          marginBottom: 30,
          maxWidth: 300,

          p: {
            fontSize: 14,
          },

          h5: {
            fontSize: 20,
          },
        },
      },
    },
  }),
};

export default Members;
